import { PageContentContainer } from '../../Layout';
import logoPlaceholder from '../../../Assests/Images/logoPlaceholder';
import useUser from '../../../hooks/useUser';
import {
  AllReadMessageIcon,
  ChannelThumbnailContainer,
  ChevRight,
  EndSection,
  LogoutTitle,
  PagesWrapper,
  PopupContainer,
  StyledChannelThumbnail,
  StyledLogo,
  StyledUserAvatar,
  UnreadMessageIcon,
  LogoutContainer2,
  LogoutContainer3,
  LogoutIcon,
  AddAccountIcon,
  PendingReviewCount,
} from './StyledComponents';
import SecondaryItem from './SecondaryItem';
import PrimaryItem from './PrimaryItem';
import { getChatUrl } from '../../../Utils';
import { useState } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideClicked';
import { isACompany } from '../../../Utils/channel';
import { useNavigate } from 'react-router-dom';
import { BellActive, BellDeactive, LinkWithHover, SettingIcon } from './TalentContent';
import { AddChannelAccountPopop } from '../AddChannelAccountPopop';
import { useChannelAccounts } from '../Utils/useChannelAccounts';
import { ToggleTheme } from '../../../Elements/ToggleTheme';
import { NewTag } from '../../../Elements/Tags/NewTag';
import { useTheme } from '../../ThemeProvider/useTheme';
import { ThemeStates } from '../../../api/interfaces';
import styled from 'styled-components';
import { MdiIcons, MdiIconsAction } from '../../../Elements/MdiIcons';
import { mdiBriefcaseOutline, mdiCartPercent, mdiSquareEditOutline, mdiTabSearch } from '@mdi/js';
import { useChannelOwnJobs } from '../Utils/useChannelOwnJobs';
import { JobListForOwner } from '../../../Interfaces/Job';
import { GoogleAnalytic } from '../../../Utils/ga';
import { PublishByCartModal } from '../PublishByCartModal';
import { useChannelData } from '../../SmartBanner/Hooks/useChannelData';

interface Props {
  onlyLogo: boolean;
  hasTeammate: boolean;
}
const ChannelContent: React.FC<Props> = ({ onlyLogo }) => {

  const { channelInfo, channelToken } = useUser();
  const [isPopUpVisible, setIsPopupVisible] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const { logout, accountsList, switchTo } = useChannelAccounts(addPopup);
  const name = channelInfo?.name;
  const { popupRef } = useOutsideAlerter(() => setIsPopupVisible(false));
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const { cartItem } = useChannelOwnJobs(Boolean(channelInfo?.id))
  const [sCartItem, setSCartItem] = useState<JobListForOwner | undefined>()
  const { userNumbers } = useChannelData(true)

  return (
    <PageContentContainer style={{ height: '100%' }}>
      <PagesWrapper>
        <StyledLogo />
        {!onlyLogo && (
          <>
            <PrimaryItem
              destination={'/job/create'}
              data-testid='menu-channel-content'
              label='Post a Job'
            />
            {/* <SecondaryItem destination='/' isActive={location.pathname === '/'} label='Home' /> */}
            <SecondaryItem isActive={location.pathname.startsWith('/job/search/')} destination='/job/search/all_categories' label='Jobs' />
            <SecondaryItem isActive={location.pathname.startsWith('/talent/search/')} destination='/talent/search/all_categories?page=1' label='Talent' />
            {/* <SecondaryItem destination='/feed' label='Feed' /> */}
            {/* <SecondaryItem destination='/top-talent' label='Leaderboards' style={{ display: 'none' }}/> */}
            {!!channelInfo?.canSearchAdvanced && (
              <SecondaryItem destination='/channel/discovery'
                label={
                  <>
                    {'Discover'}
                    <span style={{ fontSize: 12 }}>
                      {/* (beta) */}
                    </span>
                  </>
                }
              />
            )}
            <SecondaryItem destination='/videos'
              className='menu-navigation-action__video'
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  Video
                  <NewTag />
                </div>
              }
            />
            {(!isACompany(channelInfo) && false) && (
              <SecondaryItem destination='/forum'
                label={
                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    Forum
                  </div>
                }
              // className='force-hidden'
              />
            )}
            <SecondaryItem destination='/how-it-works' label='How It Works' />
            {/* <SecondaryItem destination='/channel/pricing' label='Pricing' /> */}
            <EndSection>

              {(!!cartItem && false) && (
                <CartIcon path={mdiCartPercent} onClick={() => {
                  GoogleAnalytic.CartIconClick()
                  setSCartItem(cartItem)
                }} />
              )}


              <LinkWithHover to={'/channel/notifications'}>
                {channelInfo?.unreadNotificationCount ? <BellActive /> : <BellDeactive />}
              </LinkWithHover>
              <a
                href={getChatUrl(channelToken)}
                target='_blank'
                rel='noreferrer'
              >
                {channelInfo?.unreadMessageCount ? (
                  <UnreadMessageIcon />
                ) : (
                  <AllReadMessageIcon />
                )}
              </a>
              <StyledUserAvatar
                logoUrl={
                  typeof channelInfo?.avatar === 'string'
                    ? channelInfo?.avatar
                    : logoPlaceholder
                }
                alt={name}
                onClick={() => setIsPopupVisible((prevState) => !prevState)}
              />
              {isPopUpVisible && (
                <PopupContainer ref={popupRef}>
                  <ChannelThumbnailContainer>
                    <StyledChannelThumbnail
                      variant='TYPE1'
                      avatar={channelInfo?.avatar || null}
                      isCompany={isACompany(channelInfo)}
                      abvSubscribers={channelInfo?.abvSubscribers || ''}
                      channelName={channelInfo?.name}
                      isClickable={true}
                      channelId={channelInfo?.id}
                    />
                    <div style={{ flex: 1 }} />
                    <ChevRight />
                  </ChannelThumbnailContainer>

                  <div className='row' onClick={() => {
                    navigate(`/channel/${channelInfo?.id}`)
                    setIsPopupVisible(false);
                  }} style={{ paddingLeft: 20 }}>
                    <StyledMdi className='icon' path={mdiBriefcaseOutline} />
                    My Jobs
                  </div>

                  <div className='row' onClick={() => {
                    navigate(
                      channelInfo?.canSearchAdvanced
                        ? `/channel/${channelInfo?.id}?t=DH`
                        : '/channel/discovery'
                    )
                    setIsPopupVisible(false);
                  }} style={{ paddingLeft: 20 }}>
                    <StyledMdi className='icon' path={mdiTabSearch} />
                    Discover and Hire
                  </div>

                  <div className='row' onClick={() => {
                    navigate('/review')
                    setIsPopupVisible(false);
                  }} style={{ paddingLeft: 20 }}>
                    <StyledMdi className='icon' path={mdiSquareEditOutline} />
                    Talent Review
                    {userNumbers > 0 && (
                      <>
                        <div style={{ flex: 1 }} />
                        <PendingReviewCount>{userNumbers} Pending</PendingReviewCount>
                      </>
                    )}
                  </div>

                  <div className='row' onClick={() => {
                    navigate('/channel/settings')
                    setIsPopupVisible(false);
                  }} style={{ paddingLeft: 20 }}>
                    <SettingIcon className='icon' />
                    Settings
                  </div>

                  <div className='row' onClick={() => {
                    toggleTheme();
                    setIsPopupVisible(false);
                  }} style={{ paddingLeft: 20 }}>
                    <ToggleTheme />
                    {theme === ThemeStates.Light ? 'Dark' : 'Light'} Mode
                  </div>

                  <LogoutContainer2
                    style={{ marginTop: '0', borderTopWidth: 1, paddingLeft: 20 }}
                    onClick={logout}
                  >
                    <LogoutIcon />
                    <LogoutTitle>Logout</LogoutTitle>
                  </LogoutContainer2>

                  {!!accountsList?.length && (
                    <>
                      <LogoutContainer3>
                        <LogoutTitle style={{ margin: 0 }}>Other accounts:</LogoutTitle>
                      </LogoutContainer3>
                      {accountsList.map((al, i) => (
                        <LogoutContainer3
                          key={al.id}
                          hasHover={true}
                          onClick={() => {
                            switchTo(al);
                            document.location.reload();
                          }}
                          style={{ paddingTop: 1, paddingLeft: 20, paddingBottom: i === (accountsList.length - 1) ? 1 : 8 }}
                        >
                          <img src={al.avatar} alt={al.name} />
                          <LogoutTitle>{al.name}</LogoutTitle>
                        </LogoutContainer3>
                      ))}
                    </>
                  )}

                  <LogoutContainer2 onClick={() => setAddPopup(true)}>
                    <AddAccountIcon />
                    <LogoutTitle>Add Account</LogoutTitle>
                  </LogoutContainer2>
                </PopupContainer>
              )}

              {addPopup && (
                <AddChannelAccountPopop
                  close={() => setAddPopup(false)}
                />
              )}

              {!!sCartItem && (
                <PublishByCartModal
                  close={() => setSCartItem(undefined)}
                  cartItem={sCartItem}
                />
              )}
            </EndSection>
          </>
        )}
      </PagesWrapper>
    </PageContentContainer>
  );
};
export default ChannelContent;


const StyledMdi = styled(MdiIcons)`
  path {
    fill: var(--color-gray3)
  }
`
const CartIcon = styled(MdiIconsAction)`
  width: 24px;
  height: 24px;
  margin: 0 10px 0px 0px;
  path {
    fill: var(--color-primary)
  }
`