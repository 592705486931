import React, { Suspense, ReactElement } from 'react';
const CreateJob = lazyWithRetry(() => import('./Pages/Job/Create/ByChannel'));
const EditJob = lazyWithRetry(() => import('./Pages/Job/Edit'));
const JobDetails = lazyWithRetry(() => import('./Pages/Job/details'));
const ChannelDetail = lazyWithRetry(() => import('./Pages/Channel/Detail'));
const Review = lazyWithRetry(() => import('./Pages/Channel/Review'));
const NotVerifiedChannel = lazyWithRetry(() => import('./Pages/Channel/Review/NotVerified'));
const Pricing = lazyWithRetry(() => import('./Pages/Channel/PricingPage'));
const OneClickHiring = lazyWithRetry(() => import('./Pages/Channel/OneClickHiring'));
const TermsAndCondition = lazyWithRetry(() => import('./Pages/TermsAndConditions'));
const PrivacyPolicy = lazyWithRetry(() => import('./Pages/PrivacyPolicy'));
const FAQs = lazyWithRetry(() => import('./Pages/FAQ'));
const CookiePolicy = lazyWithRetry(() => import('./Pages/CookiePolicy'));
const BookMeAgreement = lazyWithRetry(() => import('./Pages/BookMeAgreement'));
const BookMeAgreementForBuyer = lazyWithRetry(() => import('./Pages/BookMeAgreementForBuyer'));
const BookMeAgreementForClient = lazyWithRetry(() => import('./Pages/BookMeAgreementForClient'));
const VerifiedDescription = lazyWithRetry(
  () => import('./Pages/Channel/VerifiedDescription')
);
const VerifiedDescriptionYTError = lazyWithRetry(
  () => import('./Pages/Channel/VerifiedDescriptionYTError')
);
const JobSearch = lazyWithRetry(() => import('./Pages/Job/Search'));
const LowApplicants = lazyWithRetry(() => import('./Pages/Job/LowApplicants'));
const TalentSignup = lazyWithRetry(() => import('./Pages/Talent/Signup'));
const ErrorPage = lazyWithRetry(() => import('./Pages/ErrorPage'));
// const TeamMembers = lazyWithRetry(() => import('./Pages/TeamMembers'));
const ExpressLogin = lazyWithRetry(() => import('./Pages/ExpressLogin/User'));
const ChannelExpressLogin = lazyWithRetry(
  () => import('./Pages/ExpressLogin/Company')
);
const TalentWizard = lazyWithRetry(
  () => import('./Pages/Talent/ProfileWizard')
);
const Walkthrough = lazyWithRetry(
  () => import('./Pages/Walkthrough')
);
const LeadPipeline = lazyWithRetry(
  () => import('./Pages/LeadPipeline')
);
const NotFoundPage = lazyWithRetry(
  () => import('./Pages/ErrorPage/NotFoundPage')
);
const UserHandle = lazyWithRetry(
  () => import('./Pages/UserHandle')
);
const DeleteInit = lazyWithRetry(
  () => import('./Pages/ErrorPage/DeleteInit')
);
const Home = lazyWithRetry(() => import('./Pages/HomePage'));
const TryJobs = lazyWithRetry(() => import('./Pages/HomePage/Landing'));
const VideoEditorsLanding = lazyWithRetry(() => import('./Pages/VideoEditorsLanding'));
const VideoEditorsLandingV2 = lazyWithRetry(() => import('./Pages/VideoEditorsLandingV2'));
const ThumbnailDesignerLanding = lazyWithRetry(() => import('./Pages/ThumbnailDesignerLanding'));
const ScripwritersLanding = lazyWithRetry(() => import('./Pages/ScripwritersLanding'));
const TalentDetail = lazyWithRetry(() => import('./Pages/Talent/Detail'));
const RedirectProfileNew = lazyWithRetry(() => import('./Pages/Talent/profile-new-redirect'));
const TalentDetailNew = lazyWithRetry(() => import('./Pages/Talent/DetailNew'));
const TalentTimeline = lazyWithRetry(() => import('./Pages/Talent/Timeline'));
const TalentPosts = lazyWithRetry(() => import('./Pages/Talent/Posts'));
const TalentNotifications = lazyWithRetry(() => import('./Pages/TalentNotifications'));
const BookmeCheckout = lazyWithRetry(() => import('./Pages/Talent/BookmeCheckout'));
const MailBox = lazyWithRetry(() => import('./Pages/MailBox'));
const TalentList = lazyWithRetry(() => import('./Pages/Talent/Search'));
const Login = lazyWithRetry(() => import('./Pages/Login'));
const AboutProposal = lazyWithRetry(() => import('./Pages/AboutProposal'));
const ChannelList = lazyWithRetry(() => import('./Pages/ChannelList'));
const ChannelSignup = lazyWithRetry(() => import('./Pages/Channel/Signup'));
const SignUp = lazyWithRetry(() => import('./Pages/SignUp'));
const OthersVitrine = lazyWithRetry(() => import('./Pages/Talent/Vitrine'));
const UserSettings = lazyWithRetry(() => import('./Pages/Talent/Settings'));
const ChannelSettings = lazyWithRetry(() => import('./Pages/Channel/Settings'));
const Discovery = lazyWithRetry(() => import('./Pages/Channel/Discovery'));
const ChannelReferral = lazyWithRetry(() => import('./Pages/Referral'));
const SetPassword = lazyWithRetry(() => import('./Pages/SetPassword'));
const Videos = lazyWithRetry(() => import('./Pages/Videos'));
const TopVideos = lazyWithRetry(() => import('./Pages/TopVideos'));
const VideoForum = lazyWithRetry(() => import('./Pages/VideoForum'));
const VForumPage = lazyWithRetry(() => import('./Pages/VForumPage'));
const RecentMatchings = lazyWithRetry(() => import('./Pages/RecentMatchings'));
const VideoPlay = lazyWithRetry(() => import('./Pages/VideoPlay'));
const TopTalent = lazyWithRetry(() => import('./Pages/TopTalent'));
const HowItWorks = lazyWithRetry(() => import('./Pages/HowItWorks'));
const ReferralLanding = lazyWithRetry(() => import('./Pages/ReferralLanding'));
const ChannelNotifications = lazyWithRetry(() => import('./Pages/ChannelNotifications'));
const UserVitrine = lazyWithRetry(
  () => import('./Pages/Profile/Talent/Vitrine')
);
const JobApplicants = lazyWithRetry(
  () => import('./Pages/Channel/Jobs/Applicants')
);
const LandingPage = lazyWithRetry(() => import('./Pages/LandingPage'));
const Feed = lazyWithRetry(() => import('./Pages/Feed'));

const SuccessfulPayment = lazyWithRetry(
  () => import('./Pages/Job/Checkout/SuccessfulPayment')
);
const SuccessfulWithoutPayment = lazyWithRetry(
  () => import('./Pages/Job/Checkout/SuccessfulWithoutPayment')
);
const FailedPayment = lazyWithRetry(
  () => import('./Pages/Job/Checkout/FailedPayment')
);
const CheckoutJob = lazyWithRetry(() => import('./Pages/Job/Checkout'));
const PrePublishPage = lazyWithRetry(() => import('./Pages/Job/Checkout/pre-publish'));
const AfterPublish = lazyWithRetry(() => import('./Pages/Job/AfterPublish'));
const ShareJob = lazyWithRetry(() => import('./Pages/Job/Share'));
const CompanyProfileWizard = lazyWithRetry(() => import('./Pages/Channel/CompanyProfileWizard'));
const ShareReviewPage = lazyWithRetry(() => import('./Pages/ShareReview'));
const ShareBadgePage = lazyWithRetry(() => import('./Pages/ShareBadge'));
// const Page2023Recap = lazyWithRetry(() => import('./Pages/2023Recap'));
const ShareBussinessCard = lazyWithRetry(() => import('./Pages/ShareBussinessCard'));
const ShareReels = lazyWithRetry(() => import('./Pages/ShareReels'));
const ShareTimeline = lazyWithRetry(() => import('./Pages/ShareTimeline'));
const ShareCenterTalent = lazyWithRetry(() => import('./Pages/ShareCenterTalent'));
const ShareTalentProfile = lazyWithRetry(() => import('./Pages/ShareTalentProfile'));
const ShareTalentProfileNew = lazyWithRetry(() => import('./Pages/ShareTalentProfileNew'));
const BadgesDescription = lazyWithRetry(() => import('./Pages/Talent/BadgesDescription'));
const Suspend = lazyWithRetry(() => import('./Pages/Suspend'));
const TalentVerification = lazyWithRetry(() => import('./Pages/Channel/talentVerification'));
const TalentReview = lazyWithRetry(() => import('./Pages/Channel/talent-review'));
const CallbackVerifyTwitter = lazyWithRetry(() => import('./Components/Talent/Settings/CallbackTwitter'));
const TalentReviewWrapper = lazyWithRetry(() => import('./TalentReviewWrapper'));
const ThinkMedia = lazyWithRetry(() => import('./Pages/ThinkMedia'));
const Nolan = lazyWithRetry(() => import('./Pages/Nolan'));
const CreatorRoles = lazyWithRetry(() => import('./Pages/CreatorRoles'));
const Discover$HireDescriptions = lazyWithRetry(() => import('./Pages/Discover&HireDescription'));


import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Loading from './Elements/YtIcons/Loading';
import { LocalStorageKeys } from './Interfaces';
import OnlyChannelPage from './Pages/ErrorPage/OnlyChannel';
import OnlyTalentPage from './Pages/ErrorPage/OnlyTalent';
import { lazyWithRetry } from './Utils';

// if we use 'role' in useUser we face bug for deciding user is Talent Or company
// then we use 'localStorage.getItem' for such deciding
const YtRoutes: React.FC = () => {
  interface SmartRoute {
    destination: ReactElement;
  }
  const OnlyTalentRoute: React.FC<SmartRoute> = ({ destination }) => {
    return localStorage.getItem(LocalStorageKeys.TalentToken) ? (
      destination
    ) : (
      <OnlyTalentPage />
    );
  };
  const OnlyChanneltRoute: React.FC<SmartRoute> = ({ destination }) => {
    return localStorage.getItem(LocalStorageKeys.ChannelToken) ? (
      destination
    ) : (
      <OnlyChannelPage />
    );
  };
  const PrivateRoute: React.FC<SmartRoute> = ({ destination }) => {
    return (localStorage.getItem(LocalStorageKeys.ChannelToken) || localStorage.getItem(LocalStorageKeys.TalentToken)) ? (
      destination
    ) : (
      <Navigate to={'/login'} />
    );
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </div>
      }
    >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/try-jobs' element={<TryJobs />} />
        <Route path='/yt-video-editors' element={<VideoEditorsLanding />} />
        <Route path='/yt-video-editors-v2' element={<VideoEditorsLandingV2 />} />
        <Route path='/yt-thumbnail-designers' element={<ThumbnailDesignerLanding />} />
        <Route path='/yt-scriptwriters' element={<ScripwritersLanding />} />
        {/*<Route path='/team-members' element={<TeamMembers />} />*/}
        <Route path='/suspend' element={<Suspend />} />
        {/* /////////// Only-Not_Login/////////// */}
        <Route path='/channel/signup' element={<ChannelSignup />} />
        <Route path='/talent/signup' element={<TalentSignup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/about-proposals' element={<AboutProposal />} />
        <Route path='/outreach-list' element={<ChannelList />} />



        <Route path='/review/*' element={<TalentReviewWrapper />}>
          <Route index={true} element={<Review />} />
          <Route path=':talentId' element={<TalentReview />} />
          <Route path=':talentId/videos' element={<TalentVerification />} />
        </Route>



        <Route
          path='/channel/discovery'
          element={<OnlyChanneltRoute destination={<Discovery />} />}
        />
        <Route
          path='/channel/settings'
          element={<OnlyChanneltRoute destination={<ChannelSettings />} />}
        />
        <Route
          path='/channel/referral'
          element={<OnlyChanneltRoute destination={<ChannelReferral />} />}
        />
        <Route
          path='/channel/notifications'
          element={<OnlyChanneltRoute destination={<ChannelNotifications />} />}
        />
        <Route
          path='/only-verified-channel'
          element={<NotVerifiedChannel />}
        />

        {/* /////////// Only-Channel/////////// */}
        <Route
          path='/channel/pricing'
          element={<OnlyChanneltRoute destination={<Pricing />} />}
        />
        <Route
          path='/channel/pricing/one-click-hire'
          element={<OnlyChanneltRoute destination={<OneClickHiring />} />}
        />

        <Route
          path='/job/create'
          element={<OnlyChanneltRoute destination={<CreateJob />} />}
        />
        <Route
          path='/jobs/:id/checkout'
          element={<OnlyChanneltRoute destination={<CheckoutJob />} />}
        />
        <Route
          path='/jobs/:id/pre-publish'
          element={<OnlyChanneltRoute destination={<PrePublishPage />} />}
        />
        <Route
          path='/jobs/:id/after-publish'
          element={<OnlyChanneltRoute destination={<AfterPublish />} />}
        />
        <Route
          path='/channel/edit/job/:id'
          element={<OnlyChanneltRoute destination={<EditJob />} />}
        />

        <Route
          path='/channel/job/:id/applicants'
          element={<OnlyChanneltRoute destination={<JobApplicants />} />}
        />
        <Route
          path='/channel/discovery-list'
          element={<OnlyChanneltRoute destination={<JobApplicants />} />}
        />

        <Route
          path='/channel/share-job/:jobId'
          element={<OnlyChanneltRoute destination={<ShareJob />} />}
        />
        <Route
          path='/company/profile-wizard'
          element={<OnlyChanneltRoute destination={<CompanyProfileWizard />} />}
        />

        {/* /////////// Only-Talent/////////// */}

        <Route
          path='/talent/profile-wizard'
          element={<OnlyTalentRoute destination={<TalentWizard />} />}
        />
        <Route
          path='/talent/walkthrough'
          element={<OnlyTalentRoute destination={<Walkthrough />} />}
        />
        <Route
          path='/talent/lead-pipeline'
          element={<OnlyTalentRoute destination={<LeadPipeline />} />}
        />
        <Route
          path='/talent/edit/vitrine'
          element={<OnlyTalentRoute destination={<UserVitrine />} />}
        />
        <Route
          path='/talent/settings'
          element={<OnlyTalentRoute destination={<UserSettings />} />}
        />
        <Route
          path='/talent/notifications'
          element={<OnlyTalentRoute destination={<TalentNotifications />} />}
        />

        <Route
          path='/talent/bookme-checkout/:talentId'
          element={<PrivateRoute destination={<BookmeCheckout />} />}
        />
        {/* <Route
          path='/mailbox/?:threadId'
          element={<PrivateRoute destination={<MailBox />} />}
        /> */}

        <Route path='/mailbox/*' element={<PrivateRoute destination={<Outlet />} />}>
          <Route index={true} element={<MailBox />} />
          <Route path=':threadId' element={<MailBox />} />
        </Route>


        {/* //////TalentRelated//////// */}
        <Route path='/talent/search/' element={<TalentList />} />
        <Route path='/talent/search/:filter' element={<TalentList />} />
        <Route path='/talent/vitrine/:id' element={<OthersVitrine />} />
        <Route path='/talent/profile-new/:id' element={<RedirectProfileNew />} />
        <Route path='/talent/profile-old/:id' element={<TalentDetail />} />
        <Route path='/talent/profile/:id' element={<TalentDetailNew />} />
        <Route path='/talent/timeline/:id' element={<TalentTimeline />} />
        <Route path='/talent/posts/:id' element={<TalentPosts />} />
        <Route path='/talent/share-review/:talentId/:channelId' element={<ShareReviewPage />} />
        <Route path='/talent/share-badge/:talentId' element={<ShareBadgePage />} />
        {/* <Route path='/talent/2023-recap/:talentId' element={<Page2023Recap />} /> */}
        <Route path='/talent/business-card/:talentId' element={<ShareBussinessCard />} />
        <Route path='/talent/share-reel/:talentId' element={<ShareReels />} />
        <Route path='/talent/share-timeline/:talentId' element={<ShareTimeline />} />
        <Route path='/talent/share-profile/:talentId' element={<OnlyTalentRoute destination={<ShareTalentProfile />} />} />
        <Route path='/talent/share-profile-new/:talentId' element={<ShareTalentProfileNew />} />
        <Route path='/talent/share-space' element={<OnlyTalentRoute destination={<ShareCenterTalent />} />} />
        <Route path='/talent/badges-description' element={<BadgesDescription />} />

        {/* ///////////Redirect/////////// */}
        <Route path='/redirect/user/express' element={<ExpressLogin />} />
        <Route path='/redirect/verify/twitter' element={<CallbackVerifyTwitter />} />
        <Route
          path='/redirect/company/express'
          element={<ChannelExpressLogin />}
        />
        {/* ///////////Job/////////// */}
        <Route path='/job/search' element={<JobSearch />} />
        <Route path='/job/search/:filter' element={<JobSearch />} />
        <Route path='/job/few-applicants' element={<LowApplicants />} />
        <Route path='/job/few-applicants/:filter' element={<LowApplicants />} />
        <Route path='/job/:id' element={<JobDetails />} />

        {/* //////ChannelRelated//////// */}
        <Route path='/channel/:id' element={<ChannelDetail />} />
        <Route
          path='/channel/verified-channels-description'
          element={<VerifiedDescription />}
        />
        <Route
          path='/channel/verified-channels-description/yt-error'
          element={<VerifiedDescriptionYTError />}
        />
        <Route
          path='/signup'
          element={<SignUp />}
        />
        <Route
          path='/terms-and-conditions'
          element={<TermsAndCondition />}
        />
        <Route
          path='/book-a-call-terms-conditions'
          element={<BookMeAgreementForBuyer />}
        />
        <Route
          path='/book-a-call-clients-agreement'
          element={<BookMeAgreementForClient />}
        />
        <Route
          path='/privacy-policy'
          element={<PrivacyPolicy />}
        />
        <Route
          path='/faq'
          element={<FAQs />}
        />
        <Route
          path='/cookie-policy'
          element={<CookiePolicy />}
        />
        <Route
          path='/book-me-agreement'
          element={<BookMeAgreement />}
        />
        {/* //////////////////////////// */}

        <Route path='/error' element={<ErrorPage />} />
        <Route path='/creativity' element={<LandingPage />} />
        <Route path='/feed' element={<Feed />} />
        <Route
          path='/publish/job/success'
          element={<SuccessfulWithoutPayment />}
        />
        <Route path='/payment/success' element={<SuccessfulPayment />} />
        <Route path='/payment/failed' element={<FailedPayment />} />
        <Route path='/thinkmedia' element={<ThinkMedia />} />
        <Route path='/nolan' element={<Nolan />} />
        <Route path='/creator-roles' element={<CreatorRoles />} />
        <Route path='/discover-and-hire-descriptions' element={<Discover$HireDescriptions />} />
        <Route path='/referrals/:channelId/:referralCode' element={<ReferralLanding />} />
        <Route path='/set-password' element={<SetPassword />} />

        {/* Videos */}
        <Route path='/videos' element={<Videos />} />
        <Route path='/top-videos' element={<TopVideos />} />
        <Route path='/forum' element={<VideoForum />} />
        <Route path='/forum/post/:postId' element={<VForumPage />} />
        <Route path='/recent-placements' element={<RecentMatchings />} />
        <Route path='/videos/:videoId' element={<VideoPlay />} />
        <Route path='/top-talent' element={<TopTalent />} />
        <Route
          path='/how-it-works'
          element={<HowItWorks />}
        />

        <Route
          path='/:handle'
          element={<UserHandle />}
        />


        {/* <Route path='/404' component={NotFoundPage} /> */}
        <Route path='/deletion-initiated' element={<DeleteInit />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};
export default YtRoutes;
