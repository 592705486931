import styled from 'styled-components/macro';
import { MdErrorOutline } from 'react-icons/md';
import { Body14, Body16Style } from '../../Elements/Typo';
import { HTMLAttributes } from 'react';

const FetchError: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container data-testid='fetchError' {...props}>
      <StyledMdErrorOutline />
      <Title>Oops! Something went wrong.</Title>
      <Subtitle>
        Please try again in a minute.If this keeps happening, let<br />
        us know at <a href='mailto:support@ytjobs.co'>support@ytjobs.co</a>, and we’ll get it sorted!
      </Subtitle>
    </Container>
  );
};
export default FetchError;

const Container = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
`;
const StyledMdErrorOutline = styled(MdErrorOutline)`
  color: var(--color-error);
  width: 2.5rem;
  height: 2.5rem;
`;
const Title = styled.h5`
  ${Body16Style}
  font-weight: bold;
  line-height: 30px;
`;
const Subtitle = styled(Body14)`
  font-weight: normal;
  line-height: 22px;
  a {
    color: var(--color-secondary);
    text-decoration: underline;
  }
`;
