import styled from 'styled-components/macro';
import { MenuItem, Divider, ThumbnailContainer } from './StyledComponent';
import { NewTag } from '../../../Elements/Tags/NewTag';
import { MdiIcons } from '../../../Elements/MdiIcons';
import { mdiAccountCircleOutline, mdiAccountMultiplePlusOutline, mdiAccountPlusOutline, mdiBriefcaseOutline, mdiBriefcasePlusOutline, mdiFirework, mdiForumOutline, mdiLogin, mdiMessageVideo, mdiPoll } from '@mdi/js';
import { useTheme } from '../../ThemeProvider/useTheme';
import { ThemeStates } from '../../../api/interfaces';
import { ToggleTheme } from '../../../Elements/ToggleTheme';

interface Props {
  closeHandler: () => void;
  onlySignInUp?: boolean;
}

const NotLoginContent: React.FC<Props> = ({ closeHandler, onlySignInUp = false }) => {
  const { theme, toggleTheme } = useTheme();
  return (
    <MobileMenuContainer>

      <ThumbnailContainer style={{ border: 0 }}>

        {!onlySignInUp && (
          <>
            <StyledMenuItem to={'/talent/signup'} className='row red' onClick={closeHandler} style={{ paddingLeft: 8 }}>
              <StyledMdi className='icon' path={mdiAccountMultiplePlusOutline} />
              Join as Talent
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem to={'/signup'} className='row' onClick={closeHandler} style={{ paddingLeft: 8 }}>
              <StyledMdi className='icon' path={mdiBriefcasePlusOutline} />
              Post a Job
            </StyledMenuItem>
            <StyledMenuItem
              to={'/job/search/all_categories'}
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiBriefcaseOutline} />
              Jobs
            </StyledMenuItem>
            <StyledMenuItem
              to={'/talent/search/all_categories?page=1'}
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiAccountCircleOutline} />
              Talent
            </StyledMenuItem>
            <StyledMenuItem
              to={'/top-talent'}
              style={{ display: 'none' }}
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiPoll} />
              Leaderboards
            </StyledMenuItem>


            <StyledMenuItem
              to={'/videos'}
              className='menu-navigation-action__video'
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiMessageVideo} />
              <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
                Video
                <NewTag />
              </div>
            </StyledMenuItem>
            <StyledMenuItem
              to={'/forum'}
              style={{ display: 'none' }}
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiForumOutline} />
              <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
                Forum
              </div>
            </StyledMenuItem>

            <StyledMenuItem
              to={'/how-it-works'}
              onClick={() => {
                closeHandler();
              }}
            >
              <StyledMdi path={mdiFirework} />
              How It Works
            </StyledMenuItem>

            <div className='row' onClick={() => {
              toggleTheme();
              closeHandler();
            }} style={{ paddingLeft: 8 }}>
              <ToggleTheme />
              {theme === ThemeStates.Light ? 'Dark' : 'Light'} Mode
            </div>
          </>
        )}

        <Divider />

        <StyledMenuItem
          to={'/login?type=t'}
          className='row'
          onClick={() => {
            closeHandler();
          }}
        >
          <StyledMdi path={mdiLogin} />
          Login
        </StyledMenuItem>

        <StyledMenuItem
          to={'/signup'}
          className='row red'
          onClick={() => {
            closeHandler();
          }}
        >
          <StyledMdi path={mdiAccountPlusOutline} />
          Sign up
        </StyledMenuItem>

        <Divider />


      </ThumbnailContainer>


    </MobileMenuContainer>
  );
};
export default NotLoginContent;


const StyledMdi = styled(MdiIcons)`
  width: 16px;
  height: 16px;
  path {
    fill: var(--color-gray3)
  }
`
const MobileMenuContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 100px);
  /* height: calc(100vh - 100px); */
  margin-top: 100px;
  overflow-y: auto;
`
const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 8px;
  gap: 16px;
  margin-bottom: 0px;
  min-height: 35px;
`