import useUser from '../../../hooks/useUser';

import {
  LogoutContainer,
  LogoutTitle,
  MenuItem,
  StyledChannelThumbnail,
  ThumbnailContainer,
} from './StyledComponent';
import { FiLogOut, FiUserPlus } from 'react-icons/fi';
import { YtjobsIcons } from '../../../Interfaces';
import { TeammatesLink, ArrowRight, MobileButtonContainer, LogoutContainer3, MobileOtherAccountsContainer, ChannelThumbnailContainer, ChevRight, PendingReviewCount } from '../DesktopMenu/StyledComponents';
import { isACompany } from '../../../Utils/channel';
import { AddChannelAccountPopop } from '../AddChannelAccountPopop';
import { useState } from 'react';
import { useChannelAccounts } from '../Utils/useChannelAccounts';
import { NewTag } from '../../../Elements/Tags/NewTag';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeProvider/useTheme';
import { mdiAccountCircleOutline, mdiBriefcaseOutline, mdiBriefcasePlusOutline, mdiFirework, mdiForumOutline, mdiMessageVideo, mdiSquareEditOutline, mdiTabSearch } from '@mdi/js';
import { MdiIcons } from '../../../Elements/MdiIcons';
import styled from 'styled-components';
import { SettingIcon } from '../DesktopMenu/TalentContent';
import { ThemeStates } from '../../../api/interfaces';
import { ToggleTheme } from '../../../Elements/ToggleTheme';
import { useChannelData } from '../../SmartBanner/Hooks/useChannelData';

interface Props {
  closeHandler: () => void;
  hasTeammate: boolean;
}

const ChannelContent: React.FC<Props> = ({ closeHandler, hasTeammate }) => {
  const { channelInfo } = useUser()
  const [addPopup, setAddPopup] = useState(false)
  const { logout, accountsList, switchTo } = useChannelAccounts(addPopup)
  const navigate = useNavigate()
  const { theme, toggleTheme } = useTheme()
  const { userNumbers } = useChannelData(true)

  return (
    <MobileMenuContainer>
      <ThumbnailContainer>

        <ChannelThumbnailContainer>
          <StyledChannelThumbnail
            variant='TYPE1'
            avatar={channelInfo?.avatar || null}
            isCompany={isACompany(channelInfo)}
            abvSubscribers={channelInfo?.abvSubscribers || ''}
            channelName={channelInfo?.name}
            isClickable={true}
            channelId={channelInfo?.id}
            className='channel-menu-thumbnail'
          />
          {/* <div style={{ flex: 1 }} /> */}
          <ChevRight />
        </ChannelThumbnailContainer>


        <div className='row red' onClick={() => {
          navigate('/job/create')
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <StyledMdi className='icon' path={mdiBriefcasePlusOutline} />
          Post a Job
        </div>

        <div className='row' onClick={() => {
          navigate(`/channel/${channelInfo?.id}`)
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <StyledMdi className='icon' path={mdiBriefcaseOutline} />
          My Jobs
        </div>

        <div className='row' onClick={() => {
          navigate(
            channelInfo?.canSearchAdvanced
              ? `/channel/${channelInfo?.id}?t=DH`
              : '/channel/discovery'
          )
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <StyledMdi className='icon' path={mdiTabSearch} />
          Discover and Hire
        </div>

        <div className='row' onClick={() => {
          navigate('/review')
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <StyledMdi className='icon' path={mdiSquareEditOutline} />
          Talent Review
          {userNumbers > 0 && (
            <>
              <div style={{ flex: 1 }} />
              <PendingReviewCount>{userNumbers} Pending</PendingReviewCount>
            </>
          )}
        </div>

        <div className='row' onClick={() => {
          navigate('/channel/settings')
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <SettingIcon className='icon' />
          Settings
        </div>

        <div className='row' onClick={() => {
          toggleTheme();
          closeHandler();
        }} style={{ paddingLeft: 8 }}>
          <ToggleTheme />
          {theme === ThemeStates.Light ? 'Dark' : 'Light'} Mode
        </div>



      </ThumbnailContainer>

      {/* <PrimaryItem to={"/job/create"}>Post a Job</PrimaryItem> */}

      <StyledMenuItem
        to={'/job/search/all_categories'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiBriefcaseOutline} />
        Jobs
      </StyledMenuItem>
      <StyledMenuItem
        to={'/talent/search/all_categories?page=1'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiAccountCircleOutline} />
        Talent
      </StyledMenuItem>
      <StyledMenuItem
        to={'/videos'}
        className='menu-navigation-action__video'
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiMessageVideo} />
        <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
          Video
          <NewTag />
        </div>
      </StyledMenuItem>
      {(!isACompany(channelInfo) && false) && (
        <StyledMenuItem
          to={'/forum'}
          onClick={() => {
            closeHandler();
          }}
        >
          <StyledMdi path={mdiForumOutline} />
          <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
            Forum
          </div>
        </StyledMenuItem>
      )}

      <StyledMenuItem
        to={'/how-it-works'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiFirework} />
        How It Works
      </StyledMenuItem>


      {
        hasTeammate && false && <MobileButtonContainer>
          <TeammatesLink to={'/review'}>Talent Review <ArrowRight variant={YtjobsIcons.arrowRight} /></TeammatesLink>
        </MobileButtonContainer>
      }



      <LogoutContainer
        // style={{ borderTop: 0 }}
        onClick={logout}
      >
        <FiLogOut />
        <LogoutTitle>Logout</LogoutTitle>
      </LogoutContainer>


      {!!accountsList?.length && (
        <MobileOtherAccountsContainer>
          <LogoutContainer3 style={{ paddingTop: 12, paddingLeft: 8 }}>
            <LogoutTitle style={{ margin: 0 }}>Other accounts:</LogoutTitle>
          </LogoutContainer3>
          {accountsList.map((al, i) => (
            <LogoutContainer3
              key={al.id}
              hasHover={true}
              onClick={() => {
                switchTo(al);
                document.location.reload();
              }}
              style={{ paddingTop: 1, paddingLeft: 12, paddingBottom: i === (accountsList.length - 1) ? 1 : 8 }}
            >
              <img src={al.avatar} alt={al.name} />
              <LogoutTitle>{al.name}</LogoutTitle>
            </LogoutContainer3>
          ))}
        </MobileOtherAccountsContainer>
      )}


      <LogoutContainer
        style={{ border: 0 }}
        onClick={() => {
          setAddPopup(true);
        }}
      >
        <FiUserPlus />
        <LogoutTitle>Add Account</LogoutTitle>
      </LogoutContainer>



      {addPopup && (
        <AddChannelAccountPopop
          close={() => {
            setAddPopup(false);
          }}
        />
      )}
    </MobileMenuContainer>
  );
};
export default ChannelContent;


const StyledMdi = styled(MdiIcons)`
  width: 16px;
  height: 16px;
  path {
    fill: var(--color-gray3)
  }
`
const MobileMenuContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 80px);
  margin-top: 80px;
  overflow-y: auto;
`
const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 8px;
  gap: 16px;
`