import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { device, deviceMin } from "../../../consts/device";
import { MdiIcons } from "../../../Elements/MdiIcons";
import { mdiClose, mdiImage } from "@mdi/js";
import { useQuill } from 'react-quilljs';
import ErrorToast from "../../../Elements/Toast/Error";
import { AxiosError } from "axios";
import DOMPurify from 'dompurify';
import MagicUrl from 'quill-magic-url';
import { MBUploadImage } from "../../../Pages/MailBox/Api";
import { Attachment } from "../../../Pages/MailBox/Interfaces";
import { Viewport } from "../../../Components/Talent/DetailNew/ProfileContent/NewComponents";
import { Button } from "../../Button";
import YtLoading from "../../YtIcons/Loading";
import { makeOutreach } from "../../../api/fetchers/Talent";


interface SendMessageProps {
  myAvatar: string;
  subject: string;
  target: {
    type: 'company' | 'talent';
    id: string;
    name: string;
  };
  placeholder: string | undefined;
  initValue: string | undefined;
  onCancel: () => void;
  onSubmit: () => void;
}
export const WYSSendMessage: FC<SendMessageProps> = ({
  myAvatar,
  subject,
  target,
  placeholder,
  initValue,
  onCancel,
  onSubmit
}) => {

  const { quill, quillRef, Quill } = useQuill(
    {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', { align: [] }, { list: 'ordered' }, { list: 'bullet' }, 'link', { 'header': 1 }, { 'header': 2 }],
        ],
        magicUrl: true,
      },
      placeholder: placeholder ?? 'Compose here...',
      theme: 'snow',
    }
  );

  if (Quill && !quill) {
    try {
      Quill.register('modules/magicUrl', MagicUrl);
      const Link = Quill.import('formats/link');
      class MyLink extends Link {
        // @ts-ignore
        static create(value) {
          const node = super.create(value);
          node.setAttribute('target', '_blank');
          return node;
        }
      }
      Quill.register(MyLink, true);
    } catch (error) {
      console.error(error)
    }
  }

  const [attchs, setAttchs] = useState<Attachment[]>([])
  const [loading, setLoading] = useState(false)
  const [attLoading, setAttLoading] = useState(false)

  const clearImg = useCallback((id: string) => setAttchs(li => li.filter(l => l.id !== id)), []);

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input') as HTMLInputElement;
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input?.files?.[0];
      if (file) {
        setAttLoading(true)
        MBUploadImage(file)
          .then(({ attachment }) => !!attachment && setAttchs(list => [...list, attachment]))
          .finally(() => setAttLoading(false))
      }
    };
  }, []);

  const onSendAction = useCallback(() => {
    if (!target || !quill) return undefined;
    setLoading(true)
    makeOutreach(subject, DOMPurify.sanitize(quill.root.innerHTML), target.id, attchs.map(a => a.id))
      .then(() => {
        onSubmit();
      })
      .catch((e: AxiosError<{ message: string }>) => {
        ErrorToast(e.response?.data.message || 'Something went wrong!');
      })
      .finally(() => setLoading(false))
  }, [subject, target, attchs, quill, onSubmit]);

  const [disabled, setDis] = useState(true)

  useEffect(() => {
    if (!quill) return;
    quill.on('text-change', function (delta, oldDelta, source) {
      if (source == 'user') {
        setDis((quill?.getText() || '').replaceAll(' ', '').replaceAll('\n', '').length < 4)
      }
    });
  }, [quill]);

  useEffect(() => {
    if (quill && initValue) {
      quill.root.innerHTML = initValue;
    }
    // eslint-disable-next-line
  }, [quill]);


  return (
    <>
      <Contianer id="wys-send-message">

        <Avatar src={myAvatar} />

        <InputContainer>

          <TopSection>
            <div className="to">To: {target.name}</div>
            <div className="divider" />
            <div className="re">{subject}</div>
            <div className="divider" />
            <EditorContainer>
              <div ref={quillRef} />
            </EditorContainer>
          </TopSection>

          <AttContainer>
            {attchs.map((att) => (
              <AttItem key={att.id}>
                <ImageShowIcon path={mdiImage} />
                <p>{att.fileName}</p>
                <DeleteIcon path={mdiClose} onClick={() => clearImg(att.id)} />
              </AttItem>
            ))}
          </AttContainer>

          <ActionsSection>
            {attLoading ? <YtLoading width={16} height={22} /> : <ImageIcon path={mdiImage} onClick={selectLocalImage} />}
            <Spacer />
          </ActionsSection>

        </InputContainer>

      </Contianer>

      <Viewport viewType="mobile">
        <div style={{ flex: 1 }} />
      </Viewport>
      <RowCol className="mt">
        <Button variant="NAKE" onClick={onCancel}>Back</Button>
        <Viewport viewType="biggerThanMobile">
          <div style={{ flex: 1 }} />
        </Viewport>
        <Button
          isLoading={loading}
          disabled={!!disabled && !attchs.length}
          onClick={onSendAction}
        >Submit</Button>
      </RowCol>
    </>
  )
}

const Contianer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  @media ${device.tabletS} {
    flex-direction: column;
  }
`
const Avatar = styled.img`
  --av-msg-mb: 40px;
  width: var(--av-msg-mb);
  height: var(--av-msg-mb);
  min-width: var(--av-msg-mb);
  min-height: var(--av-msg-mb);
  border-radius: 50%;
  object-fit: fill;
`
const InputContainer = styled.div`
  min-height: 266px;
  display: flex;
  flex-direction: column;
  box-shadow: -4px 6px 7px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: 1px solid var(--color-gray55);

  @media ${deviceMin.tabletS} {
    flex: 1;
  }
  @media ${device.tabletS} {
    width: 100%;
  }
`
const TopSection = styled.div`
  width: 100%;
  padding: 8px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--color-gray55);
  }
  .to {
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-gray3);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    @media ${device.tabletS} {
      font-size: 12px;
      line-height: 19px; /* 157.143% */
    }
  }
  .re {
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-gray2);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px; /* 157.143% */
    @media ${device.tabletS} {
      font-size: 12px;
      line-height: 19px; /* 157.143% */
      overflow: unset;
      white-space: unset;
    }
  }
  @media ${device.tabletS} {
    width: calc(100%);
  }
`
const ActionsSection = styled.div`
  width: 100%;
  padding: 8px 16px;
  height: 57px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid var(--color-gray4);
`
const EditorContainer = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  @media ${device.tabletS} {
    .ql-snow.ql-toolbar button {
      width: 22px !important;
      height: 22px !important;
    }
  }
`
const AttContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  gap: 8px;
`
const AttItem = styled.div`
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 720px;
  border: 1px solid var(--color-gray55);
  > p {
    color: var(--color-gray2);
    font-size: 12px;
    line-height: 19px;
    max-width: 470px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media ${device.tabletS} {
      max-width: calc(100vw - 46px);
    }
  }
  > svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
`
const Spacer = styled.div`
  flex: 1
`
const ImageShowIcon = styled(MdiIcons)`
  > path {
    fill: var(--color-secondary);
  }
`
const ImageIcon = styled(MdiIcons)`
  cursor: pointer;
  /* margin-left: 8px; */
  > path {
    fill: var(--color-gray3);
  }
`
const DeleteIcon = styled(MdiIcons)`
  cursor: pointer;
  > path {
    fill: var(--color-gray3);
  }
`
const RowCol = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 48px;
  &.mt {
    margin-top: 8px;
  }
  > * {
    height: 52px;
  }
  
  @media ${device.mobileL} {
    padding-left: 0px;
    flex-direction: column-reverse;
    > * {
      width: 100%;
    }
  }
`