import { FC, useEffect } from "react";
import styled from "styled-components";
import { device, deviceMin } from "../../../consts/device";
import { useQuill } from 'react-quilljs';
import MagicUrl from 'quill-magic-url';
import '../../../Styles/_quil.scss';
import Quill from "quill";
import { MdiIconsAction } from "../../MdiIcons";
import { mdiClose } from "@mdi/js";


interface WYSEditorProps {
  placeholder: string | undefined;
  initValue: string | undefined;
  onChange: (v: string) => void;
  // eslint-disable-next-line
  toolbar?: any;
  onApiReady?: (q: Quill) => void;
  onClose?: () => void;
}
export const WYSEditor: FC<WYSEditorProps> = ({
  placeholder,
  initValue,
  onChange,
  toolbar = ['bold', 'italic', 'underline', { align: [] }, { list: 'ordered' }, { list: 'bullet' }, 'link', { 'header': 1 }, { 'header': 2 }],
  onApiReady = () => undefined,
  onClose,
}) => {

  const { quill, quillRef, Quill } = useQuill(
    {
      modules: {
        toolbar: [toolbar],
        magicUrl: true,
      },
      placeholder: placeholder ?? 'Compose here...',
      theme: 'snow',
    }
  );
  if (Quill && !quill) {
    try {
      Quill.register('modules/magicUrl', MagicUrl);
      const Link = Quill.import('formats/link');
      class MyLink extends Link {
        // @ts-ignore
        static create(value) {
          const node = super.create(value);
          node.setAttribute('target', '_blank');
          return node;
        }
      }
      Quill.register(MyLink, true);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!quill) return;
    quill.on('text-change', function (...args) { //args = delta, oldDelta, source
      if (args?.[2] == 'user') {
        onChange(!!quill.root.innerText.trim() ? quill.root.innerHTML : '')
      }
    })
    // eslint-disable-next-line
  }, [quill]);

  useEffect(() => {
    if (quill && initValue) {
      quill.root.innerHTML = initValue;
    }
    setTimeout(() => {
      if (quill) onApiReady(quill)
    }, 100)
    // eslint-disable-next-line
  }, [quill]);


  return (
    <Contianer id="wys-editor">
      <InputContainer>
        <TopSection>
          <EditorContainer className="WYSEditor">
            <div ref={quillRef} />
            {!!onClose && (
              <div className="icon-close">
                <MdiIconsAction path={mdiClose} onClick={onClose} />
              </div>
            )}
          </EditorContainer>
        </TopSection>
      </InputContainer>
    </Contianer>
  )
}

const Contianer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  @media ${device.tabletS} {
    flex-direction: column;
  }
`
const InputContainer = styled.div`
  min-height: 266px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--color-gray5);

  @media ${deviceMin.tabletS} {
    flex: 1;
  }
  @media ${device.tabletS} {
    width: 100%;
  }
`
const TopSection = styled.div`
  width: 100%;
  padding: 8px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--color-gray55);
  }
  .to {
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-gray3);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    @media ${device.tabletS} {
      font-size: 12px;
      line-height: 19px; /* 157.143% */
    }
  }
  .re {
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-gray3);
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 400;
    line-height: 19px; /* 157.143% */
    @media ${device.tabletS} {
      font-size: 12px;
      line-height: 19px; /* 157.143% */
      overflow: unset;
      white-space: unset;
    }
  }
  @media ${device.tabletS} {
    width: calc(100%);
  }
`
const EditorContainer = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
  .icon-close {
    position: absolute;
    right: -12px;
    top: 0;
  }
  @media ${device.tabletS} {
    .ql-snow.ql-toolbar button {
      width: 22px !important;
      height: 22px !important;
    }
  }
`