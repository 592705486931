import { ExpertiseID } from '.';
import { ChannelInfo } from './Channel';
import { ICategory, ISkills, IStyles, YtVideo } from './Talent';

export enum IsFeaturedId {
  FEATURED = '1',
  NOT_FEATURED = '0',
}

export type TLanguage = {
  id: number;
  name: string;
  nativeName: string;
}

export interface JobCoreInfo {
  id?: string; // اگه تو مرحله ادیت باشیم، سرور این شغل رو با آیدی میفرسته
  jobType: JobTypes;
  locationType: JobLocationTypes;
  functionalArea: ExpertiseID;
  locationDetails?: {
    cityId: number;
    countryId: number;
    cityName: string;
    countryName: string;
  };
  //
  maxSalary?: number | null;
  minSalary?: number | null;
  easyApply: boolean;
  externalLink?: string;
  location?: string | null;
  expiresAt?: string;
  minimumExperience?: null | 1 | 3 | 5;
  requiredLanguages?: TLanguage[];
  referralBonus?: number;
  jobTitle?: string;
  categories: (Omit<ICategory, 'isSystemAssigned'>)[];
  styles: IStyles[];
  skills: ISkills[];
  htmlContent: string;
}
export type TVideoType = 'all' | 'short' | 'normal';
export type TYoutubeVideo = Pick<YtVideo, 'id' | 'url'>
export interface JobFormQuestions
  extends Omit<JobCoreInfo, 'easyApply' | 'externalLink'> {
  startsAt: JobTime;
  idealCandidate: string;
  description: string;
  requirement: string;
  videoUrls: string[];
  videoType: TVideoType;
  youtubeVideos: TYoutubeVideo[];
  title?: string;
}
export interface IOneClickFrom {
  name: string;
  position: string;
  phone: string;
  email: string;
  preferredContact: 'email' | 'phone';
}

export interface JobListForOther extends JobCoreInfo {
  jobTitle?: string;
  company?: ChannelInfo;
  isFeatured?: number;
  status?: JobStatus;
  hasApplied: boolean;
}
export interface JobDetailForOthers extends JobListForOther {
  requirement: string | null;
  description: string | null;
  idealCandidate: string | null;
  createdAt: string;
  startsAt: string;
  videoType: TVideoType;
  youtubeVideos: TYoutubeVideo[];
}
export interface JobListForOwner extends Omit<JobListForOther, 'hasApplied'> {
  applicantCount: number;
  expiresSoon: boolean;
  expiresAt: string;
  publishedAt?: string;
}
export interface JobDetailForOwner extends JobDetailForOthers {
  applicantCount: number;
}

/////////////
export enum JobStatus {
  'ACTIVE' = 'active',
  'EXPIRED' = 'expired',
  'DRAFT' = 'draft',
  'CLOSED' = 'closed', // execute by job owner
  'PRE_PUBLISH' = 'pre-publish',
}

export enum JobTypes {
  PerProject = '1',
  FullTime = '3',
  PartTime = '5',
}
export enum JobTime {
  Asap = 'ASAP',
  WithinAMonth = 'within a month',
  WithinThreeMonths = 'within three months',
}
export enum JobLocationTypes {
  Remote = 'Remote',
  Hybrid = 'Hybrid',
  Onsite = 'Onsite',
}

export interface JobListFilters {
  functionalArea?: string;
  isFeatured?: boolean;
  channelId?: string;
  jobType?: JobTypes;
  locationType?: JobLocationTypes;
  isLowOnApplicants?: boolean;
}
////////////////////////////////////////
///////////////stripe realated//////////
///////////////////////////////////////

export type JobProductValues = string;

export interface AppliedDiscount {
  title: string;
  description: string;
  amount: number;
}
export interface JobProductsInStripe {
  category: string;
  default: 'true' | null;
  description: null | string;
  disabled: 0 | number;
  id: string;
  name: string;
  price: number;
  discountedPrice: number;
  stripeId: string;
  isPaid?: boolean;
  appliedDiscounts: AppliedDiscount[];
}
export interface Price {
  totalPrice: number
  discountedPrice: number;
}
export interface ISelectedProducts {
  [key: string]: boolean | undefined;
}
export interface CategorizedProducts {
  [key: string]: JobProductsInStripe[];
}
export interface JobProducts_PaymentHistory {
  total: number;
  paid: number;
  paidProducts: JobProductValues[];
  unpaid: number;
  unpaidProducts: JobProductValues[];
}
export type AddStripeProductsToJobs_Option = {
  id: string;
  payload: string;
};
export interface CheckJobPayment {
  singleLink: string;
  receipt: {
    payment: {
      amountTotal: number;
      amountDiscount: number;
      stripeSessionId: string;
    };
    products: JobProductsInStripe[];
  }
}
