import { FiLogOut } from 'react-icons/fi';
import useUser from '../../../hooks/useUser';
import { LocalStorageKeys } from '../../../Interfaces';

import {
  LogoutContainer,
  LogoutTitle,
  MenuItem,
  StyledTalentPopupThumbnail,
  ThumbnailContainer,
} from './StyledComponent';
import { NewTag } from '../../../Elements/Tags/NewTag';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeProvider/useTheme';
import { MdShare } from 'react-icons/md';
import { SettingIcon } from '../DesktopMenu/TalentContent';
import { ToggleTheme } from '../../../Elements/ToggleTheme';
import { ThemeStates } from '../../../api/interfaces';
import styled from 'styled-components';
import { MdiIcons } from '../../../Elements/MdiIcons';
import { mdiAccountCircleOutline, mdiBriefcaseOutline, mdiFirework, mdiFormatListText, mdiForumOutline, mdiMessageVideo, mdiPoll } from '@mdi/js';

interface Props {
  closeHandler: () => void;
}

const TalentContent: React.FC<Props> = ({ closeHandler }) => {
  const { talentInfo } = useUser();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  return (
    <>
      <ThumbnailContainer>
        <StyledTalentPopupThumbnail
          avatar={
            typeof talentInfo?.avatar === 'string' ? talentInfo?.avatar : ''
          }
          talentId={talentInfo?.id}
          isClickable={true}
          functionalArea={talentInfo?.functionalArea}
          channelName={talentInfo?.name || null}
        />
        {!!talentInfo && !!talentInfo.bestBadge && (
          <div className='row' onClick={() => {
            navigate('/talent/lead-pipeline')
            closeHandler();
          }}>
            <MdiIcons className='icon mdi' path={mdiFormatListText} />
            Lead Pipeline
            <NewTag />
          </div>
        )}
        <div className='row' onClick={() => {
          navigate('/talent/share-space')
          closeHandler();
        }}>
          <MdShare className='icon' />
          My Cards
        </div>

        <div className='row' onClick={() => {
          navigate('/talent/settings')
          closeHandler();
        }}>
          <SettingIcon className='icon' />
          Settings
        </div>

        <div className='row' onClick={() => {
          toggleTheme();
          closeHandler();
        }}>
          <ToggleTheme />
          {theme === ThemeStates.Light ? 'Dark' : 'Light'} Mode
        </div>
      </ThumbnailContainer>

      <StyledMenuItem
        to={'/job/search/all_categories'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiBriefcaseOutline} />
        Jobs
      </StyledMenuItem>
      <StyledMenuItem
        to={'/talent/search/all_categories?page=1'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiAccountCircleOutline} />
        Talent
      </StyledMenuItem>
      <StyledMenuItem
        to={'/top-talent'}
        className='menu-navigation-action__video'
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiPoll} />
        Leaderboards
      </StyledMenuItem>
      <StyledMenuItem
        to={'/videos'}
        className='menu-navigation-action__video'
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiMessageVideo} />
        <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
          Video
          <NewTag />
        </div>
      </StyledMenuItem>
      <StyledMenuItem
        to={'/forum'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiForumOutline} />
        <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'flex-start', }}>
          Forum
        </div>
      </StyledMenuItem>

      <StyledMenuItem
        to={'/how-it-works'}
        onClick={() => {
          closeHandler();
        }}
      >
        <StyledMdi path={mdiFirework} />
        How It Works
      </StyledMenuItem>



      <LogoutContainer
        onClick={() => {
          localStorage.removeItem(LocalStorageKeys.TalentToken);
          document.location.reload();
        }}
      >
        <FiLogOut />
        <LogoutTitle>Logout</LogoutTitle>
      </LogoutContainer>
    </>
  );
};
export default TalentContent;

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 8px;
  gap: 16px;
`
const StyledMdi = styled(MdiIcons)`
  width: 16px;
  height: 16px;
  path {
    fill: var(--color-gray3);
  } 
`
